
.header {
    margin: 0;
    padding: 1em;
    vertical-align: middle;
    background-color: #519872;
    align-items: center;
    .wrapper {
      margin: 0 auto;
      max-width: 800px;
    }
    h1 {
        margin: 0;
        color: white;
        font-size: 2.2rem;
    }

    .address {
        margin-top: 1em;
        display: none;
        text-align: center;
        color: white;
        border: 3px solid white;
        padding: 0.5rem;
        border-radius: 30px;
        font-weight: bold;
    }

    .connectWallet {
       float: right;
       height: 3rem;
       padding-left: 2rem;
       padding-right: 2rem;
       margin-right: 1rem;
       cursor: pointer;
       background-color: #519872;
       border: 3px solid #ffffff;
       border-radius: 30px;
       color: white;
       font-weight: bold;
       font-size: 1rem;

       &:hover {
           background-color: #ffffff;
           color: #519872;
           border: 3px solid #519872;
       }
    }
}