.route-redirect {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10rem;
  text-align: center;

  h2 {
    margin-bottom: 5rem;
  }
  .redirect-link {
    color: black;
    background-color: azure;
    padding: 1.5rem 2rem 1.5rem 2rem;
    font-size: 1.2rem;
    cursor: pointer;
    margin: 5rem;
    border-radius: 10px;

    &:hover {
      background-color: rgba(250, 225, 225, 0.705);
    }
  }
}

.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
