.App {
    text-align: center;
}

body {
    background-color: #282c34;
    color: rgb(207, 188, 188);
    font-size: 1.2rem;
}

p {
    text-align: justify;
    line-height: 2rem;
    margin: 0 1rem 0 1rem;
}

a {
    text-decoration: underline;
    color: rgb(207, 188, 188);
}

.title {
    font-size: 2rem;
    text-align: center;
    color: white;
}

.sign-button {
    background-color: #519872;
    border: none;
    color: white;
    padding: 15px 40px;
    font-size: 1.5rem;
    font-weight: bold;
    border-radius: 2rem;
    cursor: pointer;
    margin-top: 2rem;
    margin-bottom: 1rem;
}

.sign-button:hover {
    background-color: #74be97;
    color: white;
}

.title-main {
    margin-top: 2.5rem;
    margin-bottom: 2rem;
}

.signature-result {
  margin: 1em 0;
  background:rgb(46, 93, 46);
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

.result-text {
  text-align: left;
  font-size: 1.4rem;
}

textarea {
  resize: none;
  font-size: 1rem;
  width: 400px;
  text-align: center;
  height: 80px;
  padding: 0.5rem;
  margin: 1rem 0 0 0;
}

.alert-error {
  margin-top: 1rem;
  background: rgb(235, 111, 111);
  padding: 1em;
  color: white;
  font-size: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.alert-error label {
  margin: 2rem 0 2rem 0;
}

.clipboard {
  display: none;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  color: white;
}