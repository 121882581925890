body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #040D12;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

nav {
  display: flex;
  max-height: 100px;
  justify-content: space-around;
  align-items: center;
  padding: 0 20px 20px;
  background-color: #519872;;
  color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

img {
  height: 60px;
  width: 100px;
}

a {
  text-decoration: none;
}

.nav-text {
  font-size: 1rem;
  color: #fff;
  text-align: center;
}

.connectWallet {
  float: right;
  height: 3rem;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-right: 1rem;
  cursor: pointer;
  background-color: #519872;
  border: 3px solid #ffffff;
  border-radius: 30px;
  color: white;
  font-weight: bold;
  font-size: 1rem;

  &:hover {
      background-color: #ffffff;
      color: #519872;
      border: 3px solid #519872;
  }
}
 .address {
    margin-top: 1em;
    display: none;
    text-align: center;
    color: white;
    border: 3px solid white;
    padding: 0.5rem;
    border-radius: 30px;
    font-weight: bold;
}
.signButton {
  background-color: #519872;;
  border: 1px solid #519872;;
  border-radius: 1rem;
  padding: 1.5rem 3rem;
  font-size: 1rem;
  color: #fff;
  font-weight: bold;
  text-decoration: none;
  margin-top: 2rem;
  cursor: pointer;
}

.signButton:hover {
  background-color: #ffffff;
  color: #519872;
  border: 3px solid #519872;
 }

h1 {
  font-size: 2rem;
  color: #fff;
  text-align: center;
  margin: 2rem 0;
}


.results {
  font-size: 1rem;
  color: #000;
  text-align: center;
  margin: 2rem 0 2rem;
}

.res {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.description {
  margin: 0.6rem;
  width: 80%;
}

.sol-address {
  max-width: 90vh;
  padding: 0 5vh;
  background-color: #94c6b3;
  border-radius: 1rem;
}

.signature {
  margin-top: 2rem;
  max-width: 120vh;
  padding: 0 5vh;
  background-color: #94c6b3;
  border-radius: 1rem;
  min-width: 100vh;
}

textarea {
  margin-top: -.5rem;
  margin-bottom: 1.5rem;
  cursor: pointer;
}
